import { css } from '@emotion/react';
import { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill } from '~/modules/AppLayout/FlexGridCss';
export const Investment_SidePaneMenu = memo(function Investment_SidePaneMenu() {
    const theme = useThemeStore(t => t.theme);
    return (<div css={css `
        ${fill};
        background-color: ${theme === 'dark' ? '#252525' : '#efefef'};
        padding: 2px;
        gap: 4px;
        border-right: 1px solid #777777;
      `}>
      -
    </div>);
});
