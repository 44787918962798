import styled from '@emotion/styled';
import { Autocomplete } from '@mantine/core';
import { map } from 'lodash';
import { memo, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useAsyncFn, useMount, useUpdateEffect } from 'react-use';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useIntervalNow } from '~/hooks/useIntervalNow';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { SignalWallId } from '~/modules/signal-wall/SignalWallId';
import { removeAfterWhitespace } from '~/modules/symbolPlatform/removeAfterWhitespace';
import { futuresai_store } from '~/pages/futuresai/_private/futuresai_store';
import dayAPI from '~/utils/dayAPI';
/** //todo: 大單數量篩選 大單金額篩選 價格篩選 商品篩選 */
// 盤中突破日內高點 = 'RT_futuresai_01',
// 盤中突破日內低點 = 'RT_futuresai_02',
// 盤中高預估周轉率 = 'RT_futuresai_03',
// 盤中高預估量比 = 'RT_futuresai_04',
// 盤中創五日新高 = 'RT_futuresai_05',
// 盤中創五日新低 = 'RT_futuresai_06',
export const SignalWall = memo(function SignalWall() {
    const signalsStore = futuresai_store.signalWallPage.signals;
    const signalsState = useSnapshot(signalsStore.store);
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const [data, fetch] = useAsyncFn(async () => {
        return signalsStore.fetchAndUpdate();
    }, []);
    useMount(() => {
        signalsStore.toggle({
            query: SignalWallId.盤中突破日內高點,
            displayName: '盤中突破日內高點',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中突破日內低點,
            displayName: '盤中突破日內低點',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中高預估量比,
            displayName: '盤中高預估量比',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中創五日新高,
            displayName: '盤中創五日新高',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中創五日新低,
            displayName: '盤中創五日新低',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中高預估周轉率,
            displayName: '盤中高預估周轉率',
        }, false);
    });
    useIntervalNow(() => {
        fetch();
    }, 1000 * 5);
    useUpdateEffect(() => {
        fetch();
    }, [signalsState.from, signalsState.to, signalsState.sorter]);
    useUpdateEffect(() => {
        signalsStore.update();
    }, [
        signalsState.filter.text,
        signalsState.filter.minVolume,
        signalsState.filter.maxVolume,
        signalsState.filter.minAmount,
        signalsState.filter.maxAmount,
        signalsState.filter.stockFuture,
    ]);
    const adata = signalsState.data.filter(s => s.signal_id === 'qty_alert');
    //要篩選的股票
    const [filterSymbols, setFilterSymbols] = useState(false);
    //要篩選的方向
    const [filterBS, setFilterBS] = useState('all');
    //要篩選的大單成交金額
    const [filterTickValue, setFilterTickValue] = useState(500);
    //要篩選的大單數量
    const [filterTickQty, setFilterTickQty] = useState(100);
    const filterSymbolGroupName = 'signal_wall_fliter';
    const excludeSymbolGroupName = 'signal_wall_exclude';
    const filterSymbolsState = useSymbolWatchList(filterSymbolGroupName);
    const excludeSymbolsState = useSymbolWatchList(excludeSymbolGroupName);
    const [inputValue, setInputValue] = useState('');
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const symbolNumber = removeAfterWhitespace(inputValue);
            filterSymbolsState.acts.addSymbol(symbolNumber, 20);
            setInputValue('');
        }
    };
    //
    const searchDefaultSymbols = Object.entries(dictionary).map(s => ({
        symbol: s[0],
        name: s[1],
    }));
    return (<classes.container>
      <classes.signalWall.container>
        <classes.signalWall.header>
          <span>時間</span>
          <span>商品</span>
          <span>代號</span>
          <span>方向</span>
          <span>單量</span>
          <span>價格</span>
          <span>幅度</span>
          <span>金額</span>
        </classes.signalWall.header>

        <classes.signalWall.body>
          {map(adata, (datum, key) => {
            const tickQty = datum.signal_data && 'tick_qty' in datum.signal_data
                ? datum.signal_data['tick_qty']
                : 0;
            const close = datum.signal_data && 'close' in datum.signal_data ? datum.signal_data['close'] : 0;
            const tickBS = datum.signal_data && 'tick_bs' in datum.signal_data
                ? datum.signal_data['tick_bs']
                : '';
            const prevref = datum.signal_data && 'prev_ref' in datum.signal_data
                ? datum.signal_data['prev_ref']
                : 0;
            const tradeValue = tickQty * close * 0.1;
            const changePrecent = ((close - prevref) / prevref) * 100;
            const changeSymbol = changePrecent > 0 ? '+' : '';
            const filterBaseCondition = tradeValue >= filterTickValue && tickQty >= filterTickQty;
            const filterBSCondition = filterBS !== 'all' ? tickBS === filterBS : true;
            const filterWatchListSymbols = filterSymbols === true
                ? filterSymbolsState.state.symbolListArray.includes(datum.symbol)
                : true;
            const currentTime = dayAPI();
            const dataTime = dayAPI(datum.datetime);
            const diffTime = currentTime.diff(dataTime, 'minute') < 5;
            if (filterBaseCondition && filterBSCondition && filterWatchListSymbols) {
                return (<classes.signalWall.message key={key} bs={tickBS} newMessage={diffTime}>
                  <span>{dayAPI(datum.datetime).format('HH:mm:ss')}</span>
                  <span>{dictionary[datum.symbol]}</span>
                  <span>{datum.symbol}</span>
                  <span>{tickBS === 'b' ? '買進' : '賣出'}</span>
                  <span>{tickQty}張</span>
                  <classes.signalWall.price quote={changePrecent}>
                    {close?.toFixed(2)}
                  </classes.signalWall.price>
                  <classes.signalWall.price quote={changePrecent}>
                    {changeSymbol}
                    {changePrecent.toFixed(2)}%
                  </classes.signalWall.price>
                  <span>{tradeValue.toFixed(0)}萬</span>
                </classes.signalWall.message>);
            }
        })}
        </classes.signalWall.body>
      </classes.signalWall.container>
      <classes.dashboard.container>
        <classes.dashboard.board>
          <span>目前篩選大單金額: {filterTickValue}</span>
          <div onClick={() => setFilterTickValue(100)}>100萬</div>
          <div onClick={() => setFilterTickValue(300)}>300萬</div>
          <div onClick={() => setFilterTickValue(500)}>500萬</div>
          <div onClick={() => setFilterTickValue(1000)}>1000萬</div>
          <div onClick={() => setFilterTickValue(2000)}>2000萬</div>
        </classes.dashboard.board>

        <classes.dashboard.board>
          <span>目前篩選大單張數: {filterTickQty}</span>
          <div onClick={() => setFilterTickQty(10)}>10張</div>
          <div onClick={() => setFilterTickQty(50)}>50張</div>
          <div onClick={() => setFilterTickQty(100)}>100張</div>
          <div onClick={() => setFilterTickQty(200)}>200張</div>
          <div onClick={() => setFilterTickQty(300)}>300張</div>
          <div onClick={() => setFilterTickQty(400)}>400張</div>
        </classes.dashboard.board>

        <classes.dashboard.board>
          <span>目前篩選方向: {filterBS}</span>
          <div onClick={() => setFilterBS('all')}>全部</div>
          <div onClick={() => setFilterBS('b')}>買進</div>
          <div onClick={() => setFilterBS('s')}>賣出</div>
        </classes.dashboard.board>

        <classes.dashboard.board>
          <span>目前篩選選商品:</span>
          <div onClick={() => setFilterSymbols(!filterSymbols)}>
            狀態:{filterSymbols === true ? '啟用' : '停用'}
          </div>

          <Autocomplete icon={<AiOutlineSearch size={22}/>} placeholder='搜尋商品代號' data={searchDefaultSymbols.map(s => s.symbol + ' ' + s.name)} variant='unstyled' limit={100} maxDropdownHeight={200} value={inputValue} onChange={(value) => setInputValue(value)} onItemSubmit={item => {
            const symbolNumber = removeAfterWhitespace(inputValue);
            filterSymbolsState.acts.addSymbol(symbolNumber, 20);
            setInputValue('');
        }} onKeyDown={handleKeyDown}/>
          {filterSymbolsState.state.symbolListArray.map(s => {
            return (<div key={s} onClick={() => filterSymbolsState.acts.removeSymbol(s)}>
                {s}
              </div>);
        })}
        </classes.dashboard.board>
      </classes.dashboard.container>
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_horizontal_cross_center};
  `,
    signalWall: {
        container: styled.div `
      ${fill_vertical_cross_center};
      font-size: 13px;
    `,
        header: styled.div `
      ${fill_horizontal_cross_center};
      display: grid;
      grid-template-columns: 10% 10% 8% 8% 8% 10% 10% 10%;
      height: 32px;
      & > * {
        ${fill_horizontal_all_center};
      }
    `,
        body: styled.div `
      ${fill_vertical_cross_center};
      ${scrollbar2Css};
      height: calc(100% - 32px);
      gap: 1px;
    `,
        message: styled.div `
      ${fill_horizontal_cross_center};
      display: grid;
      grid-template-columns: 10% 10% 8% 8% 8% 10% 10% 10%;
      width: 100%;
      height: 32px;
      flex-shrink: 0;
      background-color: ${props => props.bs === 'b' ? '#381e1e' : props.bs === 's' ? '#0e2b1e' : ''};
      & > * {
        ${fill_horizontal_all_center};
        white-space: nowrap;
      }
      border-radius: 2px;
      border: 1px solid ${props => (props.newMessage ? '#777722' : 'transparent')};
    `,
        price: styled.div `
      color: ${props => (props.quote > 0 ? '#ff2222' : props.quote < 0 ? '#22ff22' : '')};
    `,
    },
    dashboard: {
        container: styled.div `
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: 200px 200px 200px;
      font-size: 13px;
    `,
        board: styled.div `
      ${fill_vertical_cross_center};
      align-items: start;
      & > div {
        cursor: pointer;
      }
    `,
    },
};
