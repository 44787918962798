import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Progress } from '@mantine/core';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
export const ListChangeOverview = memo(function ListChangeOverview(props) {
    const value = props.value;
    const totalChange = value.reduce((accumulator, currentValue) => {
        const changes = processQuoteToReadable(currentValue);
        return accumulator + changes.closeChangePercent;
    }, 0);
    const positiveChanges = value.filter(quote => {
        const changes = processQuoteToReadable(quote);
        return changes.closeChange > 0;
    });
    const negativeChanges = value.filter(quote => {
        const changes = processQuoteToReadable(quote);
        return changes.closeChange < 0;
    });
    const upLimitChanges = value.filter(quote => {
        return quote.close === quote.limitUpPrice;
    });
    const dnLimitChanges = value.filter(quote => {
        return quote.close === quote.limitDownPrice;
    });
    const negativeLength = negativeChanges.length;
    const positiveLength = positiveChanges.length;
    const upLimit = upLimitChanges.length;
    const dnLimit = dnLimitChanges.length;
    const flatLength = value.length - negativeLength - positiveLength;
    const positive = (positiveLength / value.length) * 100;
    const negative = (negativeLength / value.length) * 100;
    const flat = (flatLength / value.length) * 100;
    const positiveSum = positiveChanges.reduce((acc, quote) => acc + processQuoteToReadable(quote).closeChangePercent, 0);
    const negativeSum = negativeChanges.reduce((acc, quote) => acc + processQuoteToReadable(quote).closeChangePercent, 0);
    const avgPositive = positiveSum / positiveLength ?? 0;
    const avgNegative = negativeSum / negativeLength ?? 0;
    const total = value?.length ?? 0;
    //const avgChange = totalChange / value.length
    return (<styleds.container>
      <styleds.titleContent>
        <div css={css `
            ${jc.spaceAround};
            grid-column: 1 / 5;
            ${fill_horizontal_cross_center};
            & > * {
              ${fill_horizontal_all_center};
              width: auto;
            }
          `}>
          <span>漲跌比例</span>
          <span css={css `
              font-size: 13px;
            `}>
            數量 {total}
          </span>
          <span css={css `
              color: #ff3331;
              font-size: 13px;
            `}>
            均漲幅 {avgPositive.toFixed(1)}%
          </span>
          <span css={css `
              color: #4bd563;
              font-size: 13px;
            `}>
            均跌幅 {avgNegative.toFixed(1)}%
          </span>
        </div>
      </styleds.titleContent>
      <styleds.item>
        <span css={css `
            background-color: #be4c41;
            color: #fff;
          `}>
          漲停 {upLimit}
        </span>
        <span css={css `
            background-color: #4d4342;
            color: #ff4331;
          `}>
          上漲 {positiveLength}
        </span>

        <span css={css `
            background-color: #404550;
            color: #fafafa;
          `}>
          平盤 {flatLength}
        </span>
        <span css={css `
            background-color: #435047;
            color: #6bd593;
          `}>
          下跌 {negativeLength}
        </span>
        <span css={css `
            background-color: #398852;
            color: #fff;
          `}>
          跌停 {dnLimit}
        </span>
      </styleds.item>
      <styleds.barContent>
        <Progress css={css `
            &.mantine-aihjca {
              margin-top: 0;
            }
          `} mt='md' size={16} sections={[
            {
                value: positive,
                color: '#be4c41',
                label: positive.toFixed(0) + '%',
                tooltip: '上漲家數：' + positiveLength,
            },
            {
                value: flat,
                color: '#acacac',
                label: flat.toFixed(0) + '%',
                tooltip: '持平家數：' + flatLength,
            },
            {
                value: negative,
                color: '#568e6e',
                label: negative.toFixed(0) + '%',
                tooltip: '下跌家數：' + negativeLength,
            },
        ]}/>
      </styleds.barContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    width: 100%;
    height: 100%;
    gap: 6px;
  `,
    titleContent: styled.div `
    width: 100%;
    display: grid;
    grid-template-columns: 40% 19% 19% 19%;
    height: 24px;
    font-size: 14px;
    gap: 1%;
  `,
    item: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
    font-size: 14px;
    gap: 4px;
    & > span {
      ${fill_horizontal_all_center};
      height: 20px;

      border-radius: 4px;
    }
  `,
    barContent: styled.div `
    width: 100%;
    height: calc(100% - 72px);
    margin-top: 4px;
  `,
};
