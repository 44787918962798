import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSnapshot } from 'valtio';
import { investmentStore } from './investment_TopBar';
import { SignalWall } from './component/SignalWall';
export const Investment_SidePane1 = memo(function Investment_SidePane1() {
    const theme = useThemeStore(s => s.theme);
    const currentSymbol = useSnapshot(investmentStore).currentSymbol;
    return (<classes.container className={theme}>
      <SignalWall />
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 18px;
    padding: 8px;
    height: 100%;
    &.dark {
      background-color: #151515;
      color: #ffffff;
    }
    &.light {
      background-color: #ffffff;
      color: #151515;
    }
  `,
    pane: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 8px;
    padding: 4px;
    width: 336px;
  `,
    item: styled.div `
    ${fill_vertical_all_center};
    height: auto;
    border-radius: 4px;
    gap: 2px;
    padding: 2px;
    //box-shadow: 0 0 4px 2px #dedede;
  `,
};
const classCss = {
    baseWidth: css `
    width: 336px;
  `,
    baseHeight: css `
    height: 40px;
  `,
    baseFill: css `
    height: 40px;
    width: 336px;
  `,
    chartHeight: css `
    min-height: 200px;
  `,
};
